import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import useLogout from '../hook/useLogout';
import {getToken, isTokenExpired} from '../utils/auth';
import './Navbar.css';
import coloriageLogo from '../assets/coloriage.png';

const Navbar: React.FC = () => {
    const logout = useLogout();
    const [menuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const isLoggedIn = !isTokenExpired(getToken());

    useEffect(() => {
        const handleScroll = () => {
            // Check if the window has been scrolled more than 50px
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <nav className={`navbar navbar-expand-lg custom-navbar py-3 ${isScrolled ? 'navbar-sticky' : ''}`}>
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img
                        src={coloriageLogo}
                        alt="Coloriage.lu Logo"
                        className="navbar-logo"
                    />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMenu}
                    aria-controls="navbarNav"
                    aria-expanded={menuOpen}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"/>
                </button>

                <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        {isLoggedIn ? (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/dashboard">
                                        Dashboard
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/buy-credits">
                                        Pricing
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <button onClick={logout} className="nav-link btn-link">
                                        Logout
                                    </button>
                                </li>
                            </>
                        ) : (
                            <li className="nav-item">
                                <Link className="nav-link" to="/login">
                                    Login
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;